import MyOrdersListing from "pages/userDashboard/pages/myOrders/MyOrdersListing";
import BookOrdersListing from "../pages/userDashboard/pages/bookOrders/BookOrdersListing";
import OrderSummary from "../pages/userDashboard/pages/bookOrders/orderSummary/OrderSummary";
import DashboardHomeUser from "../pages/userDashboard/pages/dashboardHome/DashboardHome";
import UserSettings from "../pages/userDashboard/pages/settings/Settings";
import ViewMyOrderDetails from "pages/userDashboard/pages/myOrders/ViewMyOrderDetails";
import MyOrderReview from "pages/userDashboard/pages/myOrders/MyOrderReview";
import PaymentListing from "pages/userDashboard/pages/payment/PaymentListing";
import InvoicesLIsting from "pages/userDashboard/pages/invoices/InvoicesLIsting";
import TrackOrder from "pages/userDashboard/pages/trackOrder/TrackOrder";

export const userRoutes = [
  {
    id: 1,
    path: "/user/dashboard/home",
    element: <DashboardHomeUser />,
    enabled: true,
  },
  {
    id: 2,
    path: "/user/dashboard/settings",
    element: <UserSettings />,
    enabled: true,
  },
  {
    id: 3,
    path: "/user/dashboard/book-orders",
    element: <BookOrdersListing />,
    enabled: true,
  },
  {
    id: 31,
    path: "/user/dashboard/book-orders/order-summery",
    element: <OrderSummary />,
    enabled: true,
  },
  {
    id: 4,
    path: "/user/dashboard/my-orders",
    element: <MyOrdersListing />,
    enabled: true,
  },
  {
    id: 401,
    path: "/user/dashboard/my-orders/details/:orderId",
    element: <ViewMyOrderDetails />,
    enabled: true,
  },
  {
    id: 402,
    path: "/user/dashboard/my-orders/review/:orderID",
    element: <MyOrderReview />,
    enabled: true,
  },
  {
    id: 501,
    path: "/user/dashboard/payments",
    element: <PaymentListing />,
    enabled: true,
  },
  {
    id: 601,
    path: "/user/dashboard/invoice",
    element: <InvoicesLIsting />,
    enabled: true,
  },
  {
    id: 701,
    path: "/user/dashboard/track-order",
    element: <TrackOrder />,
    enabled: true,
  },
].filter((route) => route.enabled);
