import {
  bookingsIcon,
  downloadIcon,
  filterIcon,
  tableShortingIcon,
} from "assets/icons";
import { Button, DatePicker, Select } from "components/form";
import CustomModal from "components/modals/CustomModal";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { CgArrowsExchangeV } from "react-icons/cg";
import { Link } from "react-router-dom";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import {
  exportOrderAPI,
  getOrderListAPI,
  getOrderStatusAPI,
} from "services/user-controllers/order-controller";
import TableHead from "components/table/TableHead";
import {
  filterOperatorDropdown,
  generateId,
  myOrderFilterColumns,
} from "./order-config/orderConfig";
import TableBody from "components/table/TableBody";
import { toast } from "react-toastify";
import FilterModal from "components/filter/FilterModal";
import ColumnFilterDropdown from "components/filter/ColumnFilterDropdown";
import Pagination from "react-js-pagination";
import useDebounce from "hooks/useDebounce";
import moment from "moment";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

const MyOrdersListing = () => {
  const [additionalChargeData, setAdditionalChargeData] = useState(undefined);
  const [isAdditionalModalOpen, setIsAdditionalModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [isAdditionalBlank, setIsAdditionalBlank] = useState(false);

  //  setAdditionalHandler
  const setAdditionalChargeHandler = (charge) => {
    console.log("I AM ADDITIONAL CHARGE", charge);
    if (charge === "{}") {
      setIsAdditionalBlank(true);
    } else {
      let additionalData = {};
      let parsedAdditionalData = JSON?.parse(charge);
      additionalData = parsedAdditionalData;
      setIsAdditionalBlank(false);
      setAdditionalChargeData(additionalData);
    }
    setIsAdditionalModalOpen(true);
  };

  // Columns filter listing data
  const myOrderColumns = [
    {
      sort: "DESC",
      enable: true,
      key: "Order_id",
      name: "Order ID",
      icon: <FaArrowUp />,
    },
    {
      sort: "DESC",
      enable: true,
      key: "createdAt",
      icon: <FaArrowUp />,
      name: "Booking Date",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="nowrap">
            {moment(item[col?.key]).format("DD MMM, YYYY") || "-----"}
          </td>
        );
      },
    },
    {
      enable: true,
      key: "Order_id",
      name: "View Details",
      formatHandler: function (item, col) {
        return (
          <td key={col.key}>
            <Link
              className="tag button"
              to={`/user/dashboard/my-orders/details/${item[col?.key].replace(
                /\//g,
                "~"
              )}`}
            >
              View Details
            </Link>
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      name: "From Pin",
      key: "Frompincode",
      icon: <FaArrowUp />,
    },
    {
      sort: "DESC",
      enable: true,
      name: "To Pin",
      key: "Topincode",
      icon: <FaArrowUp />,
    },
    {
      sort: "DESC",
      enable: true,
      name: "Status",
      key: "OrderStatus",
      icon: <FaArrowUp />,
      formatHandler: function (item, col) {
        if (item?.orderStatus?.name === "Booked") {
          return (
            <td key={col?.key}>
              <span className="tag green">Booked</span>
            </td>
          );
        } else {
          return (
            <td key={col?.key}>
              <span>{item?.orderStatus?.name || "------"}</span>
            </td>
          );
        }
      },
    },
    {
      name: "ETA",
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "ExpectedDelivery",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="nowrap">
            {moment(item[col?.key]).format("DD MMM, YYYY") || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "articles",
      name: "Articles",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "Shipment_weight",
      name: "Shipment weight",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {parseFloat(item[col?.key])?.toFixed(2) || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "chargable_weight",
      name: "Chargeable Weight",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "Shipment_value",
      name: " Shipment Value",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },

    {
      key: "rate",
      sort: "DESC",
      name: "Rate",
      enable: true,
      icon: <FaArrowUp />,
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      key: "oda_amount",
      name: "ODA Charge",
      icon: <FaArrowUp />,
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },

    {
      enable: true,
      key: "additionalCharges",
      name: "Additional Charge",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            <Button onClick={() => setAdditionalChargeHandler(item[col?.key])}>
              View Charge
            </Button>
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "taxableAmount",
      name: "Taxable Amount",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      key: "gst_Amount",
      name: "GST Amount",
      icon: <FaArrowUp />,
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      key: "totalAmount",
      icon: <FaArrowUp />,
      name: "Total Amount",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "Pickuppersonname",
      name: "Pickup Person Name",
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "PickupCompanyName",
      name: "Pickup Company Name",
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "Pickupaddress",
      name: "Pickup Address",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-start">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "deliverypersonname",
      name: "Delivery Person Name",
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "deliveryaddress",
      name: "Delivery Address",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-start">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: true,
      icon: <FaArrowUp />,
      key: "OtherInfromation",
      name: "Other Information",
      formatHandler: function (item, col) {
        return (
          <td key={col.key}>
            <span className="cstm_width_set">{item[col?.key]}</span>
          </td>
        );
      },
    },

    {
      sort: "DESC",
      name: "Special Charges",
      enable: false,
      icon: <FaArrowUp />,
      // key: "PODUrl",
      formatHandler: function (col) {
        return (
          <td key={col.key}>
            <span className="cstm_width_set">120</span>
          </td>
        );
      },
    },

    {
      name: "SC status",
      sort: "DESC",
      enable: false,
      icon: <FaArrowUp />,
      // key: "PODUrl",
      formatHandler: function (item, col) {
        return (
          // <td key={col.key}>
          //   <span className="tag red cursor">Decline</span>
          // </td>
          // <td key={col.key}>
          //   <span className="tag green cursor">Approve</span>
          // </td>
          <td key={col.key}>
            <span className="tag orange cursor" onClick={() => setShow(true)}>
              Requested
            </span>
          </td>
          // <td key={col.key}>
          //   <span className="tag cursor">NA</span>
          // </td>
        );
      },
    },

    {
      sort: "DESC",
      enable: true,
      key: "MvikasDocketNo",
      icon: <FaArrowUp />,
      name: "Mvikas Docket Number",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="nowrap">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },

    {
      sort: "DESC",
      enable: true,
      key: "iteminvoice",
      icon: <FaArrowUp />,
      name: "Customer Invoice Number",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="nowrap">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },

    {
      sort: "DESC",
      enable: true,
      key: "LSPDocketNo",
      icon: <FaArrowUp />,
      name: "LSP Docket Number",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="nowrap">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },

    {
      enable: true,
      key: "imagepath",
      name: "Customer Item Invoice",
      formatHandler: function (item, col) {
        if (!item[col?.key]) {
          return (
            <td key={col.key}>
              <span
                className="tag"
                onClick={() => toast.error("Invoice not available")}
              >
                <IoMdEyeOff />
              </span>
            </td>
          );
        } else {
          return (
            <td key={col.key}>
              <span
                className="tag"
                onClick={() => viewInvoiceHandler(item[col?.key])}
              >
                <IoMdEye /> View
              </span>
            </td>
          );
        }
      },
    },

    {
      name: "POD",
      enable: true,
      key: "PODUrl",
      formatHandler: function (item, col) {
        if (!item[col?.key]) {
          return (
            <td key={col.key}>
              <span
                className="tag"
                onClick={() => toast.error("POD not available")}
              >
                <IoMdEyeOff />
              </span>
            </td>
          );
        } else {
          return (
            <td key={col.key}>
              <span
                className="tag"
                onClick={() => viewPODHandler(item[col?.key])}
              >
                <IoMdEye /> View
              </span>
            </td>
          );
        }
      },
    },

    {
      enable: true,
      key: "submitReview",
      actionId: "Order_id",
      name: "Submit Review",
      formatHandler: function (item, col) {
        return (
          <td key={col.key}>
            <Link
              to={`/user/dashboard/my-orders/review/${item[
                col?.actionId
              ].replace(/\//g, "~")}`}
              className="tag"
            >
              Submit Review
            </Link>
          </td>
        );
      },
    },

    {
      sort: "DESC",
      enable: false,
      key: "paymentMode",
      icon: <FaArrowUp />,
      name: "Payment Mode",
    },

    {
      sort: "DESC",
      enable: false,
      key: "Docketcharge",
      icon: <FaArrowUp />,
      name: "Docket Charge",
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },

    {
      sort: "DESC",
      enable: false,
      key: "fov_amount",
      name: "FOV Amount",
      icon: <FaArrowUp />,
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: false,
      key: "Fsc_amount",
      name: "FSC Amount",
      icon: <FaArrowUp />,
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: false,
      key: "Greentax",
      name: "Green Tax",
      icon: <FaArrowUp />,
      formatHandler: function (item, col) {
        return (
          <td key={col.key} className="text-center">
            {item[col?.key] || "-----"}
          </td>
        );
      },
    },
    {
      sort: "DESC",
      enable: false,
      icon: <FaArrowUp />,
      key: "Pickuppersonmobile",
      name: "Pickup Contact No.",
    },

    {
      sort: "DESC",
      enable: false,
      icon: <FaArrowUp />,
      name: "Delivery Contact",
      key: "deliverypersonmobile",
    },
    {
      sort: "DESC",
      enable: false,
      icon: <FaArrowUp />,
      key: "Customername",
      name: "Customer Information",
    },
  ].filter((column) => column.enable);

  //***************************** Handle all state ***************************//
  const [orderID, setOrderID] = useState("");
  const [totalRecord, setTotalRecord] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [orderRecordListing, setOrderRecordListing] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [processedColumnsArray, setProcessedColumnsArray] =
    useState(myOrderColumns);
  const [togglerSwitch, setTogglerSwitch] = useState(false);

  //---- state for handle sorting----//
  const [sortingToggler, setSortingToggler] = useState(false);
  const [sortingFilterField, setSortingFilterField] = useState({
    isSortApply: 0,
    sortColumnName: "",
    sortingOrder: "",
  });

  //------- handle all filter state ---------//

  const [limitRow, setLimitRow] = useState(5);
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [startDateFilter, setStartDate] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [endDateFilter, setEndDateFilter] = useState("");

  const [orderStatus, setOrderStatus] = useState("");

  const [filterModalFields, setFilterModalFields] = useState({
    columnFilterName: "",
    operatorFilterName: "",
    filterValue: "",
    isApplied: false,
  });

  const statusDebounceValue = useDebounce(orderStatus, 500);
  const searchDebounceValue = useDebounce(searchValue, 500);
  const endDateDebounceValue = useDebounce(endDateFilter, 500);
  const startDateDebounceValue = useDebounce(startDateFilter, 500);

  //***************************** Handle all function ***************************//

  const viewInvoiceHandler = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      toast?.error("Invoice not available");
      return;
    }
  };

  const viewPODHandler = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      toast?.error("POD not available");
      return;
    }
  };

  // get status list handler
  const getStatusListHandler = async () => {
    try {
      const { data } = await getOrderStatusAPI();
      if (data?.code === 200) {
        const statusList = data?.data?.map((el) => {
          return {
            ...el,
            value: el?.id,
            uniqId: generateId(),
          };
        });
        setStatusList(statusList);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // pageChangeHandler for pagination
  const handlePageChange = (event) => {
    console.log(event);
    setActivePage(event);
  };

  //------------------ Functions for handle filter andy dynamic table columns --------------------//

  // toggleColumnVisibility handler for set array of hidden column name
  // const toggleColumnVisibility = (key) => {
  //   setHiddenColumns((prevState) =>
  //     prevState.includes(key)
  //       ? prevState.filter((col) => col !== key)
  //       : [...prevState, key]
  //   );
  // };

  const toggleColumnVisibility = (key) => {
    if (!hiddenColumns?.length) {
      let updateHidden = {
        sort: "DESC",
        icon: <FaArrowUp />,
        hiddenValue: key,
      };

      let hiddenArr = [updateHidden];
      setHiddenColumns(hiddenArr);
    } else {
      if (hiddenColumns.some((el) => el.hiddenValue === key)) {
        let updatedHiddenArray = hiddenColumns.filter(
          (el) => el.hiddenValue !== key
        );
        setHiddenColumns(updatedHiddenArray);
      } else {
        let clone = [...hiddenColumns];
        let updatedHiddenCol = {
          sort: "DESC",
          icon: <FaArrowUp />,
          hiddenValue: key,
        };
        clone = [...clone, { ...updatedHiddenCol }];
        setHiddenColumns(clone);
      }
    }
    setTogglerSwitch(!togglerSwitch);
  };

  // hideAllColumnsHandler for hide all columns of table
  // const hideAllColumns = () => {
  //   setHiddenColumns(myOrderColumns.map((col) => col.key));
  // };

  const hideAllColumns = () => {
    setHiddenColumns(
      myOrderColumns.map((col) => {
        return {
          sort: col.sort,
          icon: col.icon,
          hiddenValue: col?.key,
        };
      })
    );
    setTogglerSwitch(!togglerSwitch);
  };

  // Show all columns handler for show all table's data
  // const showAllColumns = () => {
  //   setHiddenColumns([]);
  // };
  const showAllColumns = () => {
    setHiddenColumns([]);
    setTogglerSwitch(!togglerSwitch);
  };

  // sort toggle Handler
  const toggleSortHandler = (keyValue, col) => {
    let index = 0;
    let updatedCol;
    let isApplied = 0;
    let sortFields = {};
    let cloneProcessedArray = processedColumnsArray?.map((el) => el);

    if (cloneProcessedArray?.length > 0) {
      updatedCol = processedColumnsArray.find((el) => el.key === keyValue);
      index = cloneProcessedArray.findIndex((obj) => obj.key === keyValue);
      if (updatedCol?.sort === "DESC") {
        updatedCol = {
          ...updatedCol,
          sort: "ASC",
          icon: <FaArrowDown />,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === keyValue),
        };
        isApplied = 1;
      } else {
        updatedCol = {
          ...updatedCol,
          sort: "DESC",
          icon: <FaArrowUp />,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === keyValue),
        };
        isApplied = 2;
      }
    }
    cloneProcessedArray[index] = updatedCol;
    setProcessedColumnsArray(cloneProcessedArray);

    if (isApplied === 1 || isApplied === 2) {
      sortFields = {
        isSortApply: isApplied,
        sortColumnName: keyValue,
        sortingIcon: updatedCol?.icon,
        sortingOrder: updatedCol?.sort,
      };
    }
    setSortingFilterField({ ...sortFields });
    setSortingToggler(!sortingToggler);
  };

  // function for set column array with there column visibility checked by check and uncheck
  // const processedColumns = myOrderColumns.map((col) => ({
  //   ...col,
  //   isHidden: hiddenColumns.includes(col.key),
  // }));

  //------------------ Close Functions for handle filter andy dynamic table columns --------------------//

  // get order list handler for get order data
  const getOrderListHandler = async (
    page = activePage,
    search = searchDebounceValue,
    status = statusDebounceValue,
    fromDate = startDateDebounceValue
      ? moment(startDateDebounceValue).format("YYYY-MM-DD")
      : "",
    toDate = endDateDebounceValue
      ? moment(endDateDebounceValue).format("YYYY-MM-DD")
      : "",
    limit = limitRow,

    isSort = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.isSortApply
      : 0,
    sortColumnName = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.sortColumnName
      : "",
    sortingOrder = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.sortingOrder
      : "",

    column = filterModalFields?.columnFilterName,
    operator = filterModalFields?.operatorFilterName,
    value = filterModalFields?.filterValue
  ) => {
    try {
      const { data } = await getOrderListAPI(
        page,
        search,
        status,
        fromDate,
        toDate,
        limit,
        isSort,
        sortColumnName,
        sortingOrder,
        column,
        operator,
        value
      );

      if (data?.code === 200) {
        setTotalRecord(data?.data?.count || 0);
        setOrderRecordListing(data?.data?.list);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Export Order Listing Handler
  const exportOrderListHandler = async () => {
    try {
      const response = await exportOrderAPI();
      const { data, headers } = response;

      if (response.status !== 200) {
        return toast.error("Failed to download the file.");
      }

      // Create a blob from the response data
      const blob = new Blob([data], { type: headers["content-type"] });

      // Create a link element to download the file
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute with a filename
      const filename =
        headers["content-disposition"]?.split("filename=")[1] || "order.xlsx";
      link.download = filename.replace(/"/g, "");

      // Append the link to the body, click it to trigger the download, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("EXPORT ORDER LISTING RESPONSE", data);
    } catch (err) {
      console.error(err);
      toast.error("Error occurred while downloading the file.");
    }
  };

  // reset-filter handler
  const resetFilterHandler = () => {
    if (filterModalFields?.columnFilterName?.length > 0) {
      setFilterModalFields({
        columnFilterName: "",
        operatorFilterName: "",
        filterValue: "",
      });
    }
  };

  // useEffect for get order listing and handle filter
  useEffect(() => {
    getOrderListHandler();

    // eslint-disable-next-line
  }, [
    activePage,
    searchDebounceValue,
    statusDebounceValue,
    startDateDebounceValue,
    endDateDebounceValue,
    limitRow,
    sortingFilterField.isSortApply,
    sortingToggler,
    filterModalFields?.isApplied,
  ]);

  // useEffect for handle processed column
  useEffect(() => {
    const processedColumnHandler = () => {
      let cloneProcessed = myOrderColumns.map((col) => {
        return {
          ...col,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === col.key),
        };
      });

      setProcessedColumnsArray(cloneProcessed);
    };

    processedColumnHandler();
  }, [togglerSwitch]);

  useEffect(() => {
    getStatusListHandler();
  }, []);

  //***************************** Handle HTML CODE ***************************//
  return (
    <>
      <div className="my_orders_list">
        <div className="dashboard_title">
          <h4>My Orders</h4>
        </div>

        <div className="button_section">
          <div className="form_field">
            <div className="search_box">
              <IoSearchOutline />
              <input
                type="search
                "
                value={searchValue}
                placeholder="Search"
                className="from-control input"
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>

          <div className="form_field has_icon">
            <img src={bookingsIcon} alt="" className="icon" />
            <Select
              value={orderStatus}
              onChange={(e) => setOrderStatus(e.target.value)}
            >
              <option value={""}>Order Status</option>
              <option value={""}>All</option>
              {statusList?.length > 0 &&
                statusList?.map((el) => (
                  <option key={el.uniqId} value={el?.value}>
                    {el?.name}
                  </option>
                ))}
              {/* <option value={""}>Order Status</option>
              <option value="serviceable">Serviceable</option>
              <option value="All">All</option>
              <option value="Booked">Booked</option>
              <option value="In-transit">In-transit</option>
              <option value="RTO">RTO</option>
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option> */}
            </Select>
          </div>

          <div className="form_field">
            <DatePicker
              placeholder="From"
              value={startDateFilter}
              onChange={(date) => setStartDate(date)}
              isClearable={startDateFilter ? true : false}
            />
          </div>
          <div className="form_field">
            <DatePicker
              placeholder="To"
              value={endDateDebounceValue}
              onChange={(date) => setEndDateFilter(date)}
              isClearable={endDateDebounceValue ? true : false}
            />
          </div>

          {/* <Button>Search</Button> */}
        </div>

        {/*----------- Handle Filter and Column filter -------------*/}
        <div className="table_filter_wrap">
          <div className="filter_buttons">
            {/*-------- handle filters dropdown --------*/}
            <Dropdown>
              <Dropdown.Toggle onClick={() => setFilterModal(true)}>
                <img src={filterIcon} alt="" />
                <span>Filters</span>
              </Dropdown.Toggle>
            </Dropdown>

            {/* handle column filter dropdown */}
            <ColumnFilterDropdown
              columnsArray={processedColumnsArray}
              onToggleColumn={toggleColumnVisibility}
              hideAllColumns={hideAllColumns}
              showAllColumns={showAllColumns}
            />

            {/* Reset Filter */}
            {filterModalFields?.isApplied && (
              <Button onClick={resetFilterHandler}>Reset Filter</Button>
            )}
          </div>

          <Button onClick={() => exportOrderListHandler()}>
            <img src={downloadIcon} alt="" /> Export
          </Button>
        </div>

        <div className="table_wrap">
          <table className="table">
            <TableHead
              sortHandler={toggleSortHandler}
              columnsArray={processedColumnsArray}
            />
            <TableBody
              listingData={orderRecordListing}
              columnsArray={processedColumnsArray}
            />
          </table>
        </div>
        {/*-------- Handle Pagination ---------*/}
        <div className="pagination_wrapper">
          <div className="pages_count">
            <p>
              <span>Rows per pages</span>{" "}
              <Select
                value={limitRow}
                onChange={(e) => setLimitRow(e.target.value)}
              >
                <option value={5}>05</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </Select>
            </p>
            {/* <span className="page_tag">1-6 of 6</span> */}
          </div>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            pageRangeDisplayed={5}
            activePage={activePage}
            // prevPageText={"Prev"}
            // nextPageText={"Next"}
            // itemClassPrev={"prev"}
            // itemClassNext={"next"}
            onChange={handlePageChange}
            itemsCountPerPage={limitRow}
            totalItemsCount={totalRecord}
            itemClassLast={"last_page next"}
            itemClassFirst={"first_page prev"}
          />
        </div>
      </div>

      {/*----------------------- Handle All Modals ---------------------*/}
      {/* filter dropdown modal */}
      <CustomModal
        show={filterModal}
        className={"filterModal"}
        handleClose={() => setFilterModal(false)}
      >
        <FilterModal
          filterFields={filterModalFields}
          columnArray={myOrderFilterColumns}
          setModalVisibility={setFilterModal}
          setFilterFields={setFilterModalFields}
          operatorArray={filterOperatorDropdown}
        />
      </CustomModal>

      {/* View breakup modal */}
      <CustomModal
        show={isAdditionalModalOpen}
        handleClose={() => setIsAdditionalModalOpen(false)}
        className={"view_breakup_charges_modal"}
      >
        {/* <h3>{vendorName}</h3> */}
        <ul>
          {additionalChargeData && !isAdditionalBlank ? (
            Object.keys(additionalChargeData).map((keyName, i) => (
              <li key={i}>
                <h5>{keyName}</h5>
                <h5>{additionalChargeData[keyName]}</h5>
              </li>
            ))
          ) : (
            <li className="text-center">
              <h5 className="w-100">No Addition Charges Applied.</h5>
            </li>
          )}
        </ul>
      </CustomModal>

      {/* filter dropdown modal */}
      <CustomModal
        show={show}
        className={"special_charges_modal"}
        handleClose={() => setShow(false)}
      >
        <div className="wrap_sc_status">
          <h4>Special Charges Status</h4>
          <div className="wrap_list_data">
            <div className="form_field_wrap">
              <div className="form_wrap">
                <Select name="" id="">
                  <option value="">Select Status</option>
                  <option value="">Approve</option>
                  <option value="">Decline</option>
                </Select>
              </div>
            </div>
            <div className="wrap_actions">
              <h6>Created</h6>
              <span>Action:create,By Sunil, timestamp:19/10/2024,20:12:14</span>
              <span className="cstm_dots"></span>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default MyOrdersListing;
