import React, { useContext, useState } from "react";
import {
  abhinaKumar,
  aboutImg,
  adiyaBatra,
  bannerImg,
  blog1,
  blog2,
  blog3,
  gunjanSingh,
  rajatKumar,
  reviewImg,
  service1,
  service2,
  sliderImg1,
  sliderImg2,
  sliderImg3,
  teamImg1,
  truckImg,
  unmatchedImg,
} from "../../../assets/images";
import {
  calendarIcon,
  environmentalIcon,
  fastIcon,
  gpsLocation,
  linkedinIcon,
  markerIcon,
  returnIcon,
  shippingIcon,
  techIcon,
} from "../../../assets/icons";
import { Button, LoadingButton, RatingStar } from "../../../components/form";
import "./HomePage.scss";
import Slider from "react-slick";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import ProblemSolutionSection from "./section/ProblemSolution";
import {
  getOrderDetailsForTrackHomeAPI,
  getVendorForHomeAPI,
} from "services/user-controllers/masterController";
import { toast } from "react-toastify";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Placeholder from "components/placeholder/Placeholder";
import CustomModal from "components/modals/CustomModal";
import TrackOrderHome from "./section/TrackOrderHome";
import { GlobalUserContext } from "context/GlobalContext";

const HomePage = () => {
  const {
    setOrderWalletToggle,
    orderWalletToggle,
    isLoginModalOpen,
    setIsLoginModalOpen,
  } = useContext(GlobalUserContext);

  //*********************** Handle all state ********************//
  var settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [weightValue, setWeightValue] = useState();
  const [picupPinCode, setPicupPinCode] = useState("");
  const [vendorListing, setVendorListing] = useState([]);
  const [tabsChange, setTabChange] = useState("schedule");
  const [destinationPinCode, setDestinationPinCodePinCode] = useState("");

  const [searchButtonLoading, setSearchButtonLoading] = useState(false);

  const [rulesList, setRulesList] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [vendorBreakUps, setVendorBreakUps] = useState();

  const [infoModal, setInfoModal] = useState(false);
  const [viewBreakupModal, setViewBreakupModal] = useState(false);

  //-------- State for Track Order--------//
  const [orderNumber, setOrderNumber] = useState("");
  const [trackOrderApiResponse, setTrackOrderApiResponse] = useState();

  //*********************** Handle all function ********************//

  // Check Rating handler
  // const isHighestAvgRating = (item, arr) => {
  //   const highestAvgRating = arr.reduce((max, currentItem) => {
  //     return parseFloat(currentItem.avgRating) > max
  //       ? parseFloat(currentItem.avgRating)
  //       : max;
  //   }, 0);

  //   return parseFloat(item.avgRating) === highestAvgRating;
  // };

  const isHighestAvgRating = (arr) => {
    // Use reduce to find the index with the highest avgRating
    return arr.reduce((highestIndex, currentItem, currentIndex, array) => {
      return parseFloat(currentItem.avgRating) >
        parseFloat(array[highestIndex].avgRating)
        ? currentIndex
        : highestIndex;
    }, 0); // Initialize with index 0
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "picUpPinCode") {
      const zipCodeValue = value.replace(/[^0-9]/g, "");
      setPicupPinCode(zipCodeValue);
    }
    if (name === "destinationPinCode") {
      const zipCodeValue = value.replace(/[^0-9]/g, "");
      setDestinationPinCodePinCode(zipCodeValue);
    }
  };

  // get VendorListing or order handler
  const getVendorListingHandler = async (e) => {
    e.preventDefault();

    setSearchButtonLoading(true);
    try {
      const sendData = {
        sourcePincode: picupPinCode,
        destinationPincode: destinationPinCode,
        shipmentWeight: weightValue,
      };
      const { data } = await getVendorForHomeAPI(sendData);
      if (data?.code === 200) {
        if (data?.data?.length > 0) {
          setVendorListing(data?.data);
        } else {
          toast.error("No Vendor Available For These Pincode !");
          setVendorListing([]);
        }
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSearchButtonLoading(false);
    }
  };

  // get orderAndItemDetailsHandler For track order
  const orderAndItemDetailsHandler = async () => {
    try {
      const { data } = await getOrderDetailsForTrackHomeAPI(orderNumber);
      if (data.code === 200) {
        setTrackOrderApiResponse(data);
      } else {
        toast.error(data?.message);
      }
      console.log("I AM ORDER DETAILS DATA", data);
    } catch (err) {
      console.error(err);
    }
  };

  //*********************** Handle HTML CODE ********************//
  return (
    <>
      <section className="homePage" id="home">
        <div className="home_banner">
          <div className="container">
            <div className="banner_top_content">
              <h1>
                We provide logistics solution for individual customer
                <img src={reviewImg} alt="" />
              </h1>
              <img src={bannerImg} alt="" className="right_image" />
            </div>
            <div className="search_container">
              <div className="tabs">
                <button
                  type="button"
                  onClick={() => {
                    setTrackOrderApiResponse();
                    setTabChange("schedule");
                  }}
                  className={tabsChange === "schedule" ? "active" : ""}
                >
                  <img src={calendarIcon} alt="" /> Get a schedule
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setVendorListing([]);
                    setTabChange("trackOrder");
                  }}
                  className={tabsChange === "trackOrder" ? "active" : ""}
                >
                  <img src={returnIcon} alt="" />
                  Track Your Order
                </button>
              </div>
              {tabsChange === "schedule" && (
                <div className="search_section">
                  <div className="search_field">
                    <img src={markerIcon} alt="" />
                    <input
                      type="text"
                      maxLength={6}
                      name="picUpPinCode"
                      value={picupPinCode}
                      onChange={onChangeHandler}
                      className="form-control input"
                      placeholder="Enter pickup pincode"
                    />
                  </div>
                  <div className="search_field">
                    <img src={markerIcon} alt="" />
                    <input
                      type="text"
                      maxLength={6}
                      name="destinationPinCode"
                      value={destinationPinCode}
                      onChange={onChangeHandler}
                      className="form-control input"
                      placeholder="Enter destination pincode"
                    />
                  </div>
                  <div className="search_field">
                    <img src={returnIcon} alt="" />
                    <input
                      type="number"
                      value={weightValue}
                      placeholder="Weight, Kg..."
                      className="form-control input"
                      onChange={(e) => setWeightValue(e.target.value)}
                    />
                  </div>
                  <LoadingButton
                    loading={searchButtonLoading}
                    disabled={searchButtonLoading}
                    onClick={getVendorListingHandler}
                    className={searchButtonLoading ? "disabled" : ""}
                  >
                    Search Now
                  </LoadingButton>
                </div>
              )}

              {tabsChange === "trackOrder" && (
                <div className="search_section track_orders">
                  <div className="search_field">
                    <img src={gpsLocation} alt="" />
                    <input
                      type="search"
                      value={orderNumber}
                      className="form-control input"
                      placeholder="Enter your order ID"
                      onChange={(e) => setOrderNumber(e?.target?.value)}
                    />
                  </div>
                  <Button onClick={orderAndItemDetailsHandler}>Track Id</Button>
                </div>
              )}

              {/*---------------- Vendor Listing for Order & Track Order ---------------*/}
              <div className="book_order_list">
                {/*----- Vendor Listing ----*/}
                <div className="orderListing">
                  {vendorListing?.length > 0 &&
                    vendorListing.map((item, i) => (
                      <div className="order_details _page" key={item?.vendorId}>
                        {i === isHighestAvgRating(vendorListing) && (
                          <span className="recommend">
                            RECOMMENDED BY MVIKAS
                          </span>
                        )}
                        <div className="content_top">
                          <div className="content_left">
                            {item?.imagePath?.length > 0 ? (
                              <img src={item?.imagePath} alt="not-found" />
                            ) : (
                              <img src={blog1} alt="not-found" />
                            )}
                            <div className="content">
                              <h4>
                                {item?.vendorName}{" "}
                                <button
                                  type="button"
                                  onClick={() => {
                                    setRulesList(item?.rules);
                                    setInfoModal(true);
                                  }}
                                >
                                  <IoMdInformationCircleOutline />
                                </button>
                              </h4>
                              <RatingStar
                                readonly
                                initialRating={item?.rating}
                                user={`${item?.rating} Rating`}
                              />
                            </div>
                          </div>
                          <div className="price">
                            <span>Total Amount</span>
                            <h3>{item?.totalAmount || "----"}</h3>
                          </div>
                        </div>
                        <div className="content_bottom">
                          <ul>
                            <li>
                              <span>Service name</span>
                              <b>{item?.serviceName || "-----"}</b>
                            </li>
                            <li>
                              <span>Service Type</span>
                              <b>{item?.servicetype || "------"}</b>
                            </li>
                            <li>
                              <span>Taxable Amount</span>
                              <b>{item?.taxableAmount || "-----"}</b>
                            </li>
                            <li>
                              <span>GST Amount</span>
                              <b>{item?.gst_Amount || "-------"}</b>
                            </li>
                            <li>
                              <span>ETA</span>
                              <b>{item?.ExpectedDelivery || "N/A"} </b>
                            </li>
                          </ul>
                          <div className="button_wrap">
                            <Button
                              className={"light"}
                              onClick={() => {
                                setVendorName(item?.vendorName);
                                setVendorBreakUps(item?.breakups);
                                setViewBreakupModal(true);
                              }}
                            >
                              View Breakups
                            </Button>
                            <span
                              className="button"
                              onClick={() => setIsLoginModalOpen(true)}
                            >
                              Book
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                {/*------------ Track Order ---------*/}
                {trackOrderApiResponse && (
                  <TrackOrderHome trackResp={trackOrderApiResponse} />
                )}
              </div>
            </div>

            <div className="banner_bottom_content">
              <p>
                Redefining Convenience in Every Shipment. Experience seamless
                logistics and unparalleled customer care with our commitment to
                excellence.
              </p>
              <div className="image_wrap">
                <img src={truckImg} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="working_data">
          <div className="container">
            <h3>We earn trust by working efficiently</h3>
            <div className="data">
              <ul>
                <li>
                  <b>
                    <CountUp delay={2} end={17} />+
                  </b>
                  <span>Years of experience</span>
                </li>
                <li>
                  <b>
                    <CountUp delay={2} end={1000} />+
                  </b>
                  <span>Shipping worldwide</span>
                </li>
                <li>
                  <b>
                    <CountUp delay={2} end={500000} />+
                  </b>
                  <span>Delivery package</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="service_provider home_spacing">
          <div className="container">
            <div className="page_title">
              <h3>Move with the Best Logistics Company in India</h3>
              <img src={service1} alt="" />
            </div>
            <div className="content_right">
              <img src={service2} alt="" />
              <p>
                We are your answer for a seamless logistics solution! At MVIKAS
                Super Logistics, we take pride in our innovative technology and
                expertise in navigating supply chain complexities with precision
                and efficiency. As one of the best Logistics Companies in India,
                we provide excellent services tailored to your needs. Connect to
                experience the difference with MVIKAS Super Logistics; Your most
                trusted logistics service provider in India. Let us handle your
                logistics for you!
              </p>
            </div>
          </div>
        </div>
        <div className="logistics home_spacing" id="about">
          <div className="container">
            <div className="content_left">
              <div className="page_title">
                <p>ABOUT US</p>
                <h3>Move with the Best Logistics Company in India</h3>
              </div>
              <p>
                We pride ourselves as one of India's leading logistics services
                providers in India, specializing in a wide range of logistics
                services. We understand that every minute counts, so at MVIKAS
                Super Logistics, we ensure top-notch deliveries across PAN India
                within 12 hrs and globally within just 48 hrs. We guarantee
                timely delivery for all your shipments at an affordable price.
                Our commitment to excellence and innovative logistics services
                sets us apart in this ever-evolving logistics landscape. We
                bridge the gap with our shipping services, whether air, sea, or
                land. Expect to make your shipping simpler with us!
              </p>
            </div>
            <div className="content_right">
              <img src={aboutImg} alt="" />
            </div>
          </div>
        </div>
        <div className="logistic_solution home_spacing" id="services">
          <div className="container">
            <div className="page_title">
              <p>SERVICES</p>
              <h3>Provide efficient logistics solutions business</h3>
            </div>
            <div className="slider_card">
              <div className="item">
                <img src={sliderImg1} alt="" />
                <h4>Road freight</h4>
                <p>Efficient and reliable road transport for your goods.</p>
              </div>
              <div className="item">
                <img src={sliderImg2} alt="" />
                <h4>Industry solutions</h4>
                <p>Tailored logistics solutions for diverse industries</p>
              </div>
              <div className="item">
                <img src={sliderImg3} alt="" />
                <h4>Customer solutions</h4>
                <p>
                  Personalized logistics services to meet your unique needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Problem Solution */}
        <ProblemSolutionSection />
        {/* End  */}
        <div className="unmatched home_spacing" id="why">
          <div className="container">
            <div className="reason_list">
              <div className="page_title">
                <p>WHY CHOOSE US</p>
                <h3>Unmatched excellence reasons to choose us</h3>
              </div>
              <ul>
                <li>
                  <img src={fastIcon} alt="" />

                  <p>
                    <b>Fast delivery</b>
                    <span>
                      Speeding your logistics with precision and reliability
                      Swift and reliable deliveries, every time.
                    </span>
                  </p>
                </li>
                <li>
                  <img src={techIcon} alt="" />
                  <p>
                    <b>Advanced technology</b>
                    <span>
                      Harnessing cutting-edge tech for seamless logistics.
                    </span>
                  </p>
                </li>
                <li>
                  <img src={shippingIcon} alt="" />
                  <p>
                    <b>Shipping insurance</b>
                    <span>Comprehensive coverage for your peace of mind.</span>
                  </p>
                </li>
                <li>
                  <img src={environmentalIcon} alt="" />
                  <p>
                    <b>Environmental sustainability</b>
                    <span>
                      Committed to eco-friendly and sustainable practices.
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="reason_img">
              <p>
                Get in touch with us to resolve all your logistics challenges
                with our industrial-standard solutions tailored to your needs.
              </p>
              <img src={unmatchedImg} alt="" />
            </div>
          </div>
        </div>
        <div className="our_team home_spacing" id="team">
          <div className="container">
            <div className="page_title">
              <p>OUR TEAM</p>
              <h3>Our Extraordinary Team</h3>
            </div>
            <div className="team_slider">
              {/* <Slider {...settings}>
               
              </Slider> */}

              <div className="item">
                <img src={abhinaKumar} alt="" className="profile" />
                <div className="content">
                  <p>
                    <b>Abhinav Kumar</b>
                    <span>CO-FOUNDER/ CEO</span>
                  </p>
                  <Link
                    to="https://linkedin.com/in/abhinavkumarmvikas"
                    target="_blank"
                  >
                    <img src={linkedinIcon} alt="" className="icon" />
                  </Link>
                </div>
              </div>
              <div className="item">
                <img src={adiyaBatra} alt="" className="profile" />
                <div className="content">
                  <p>
                    <b>Aditya Batra</b>
                    <span>CO-FOUNDER/ COO</span>
                  </p>
                  <Link
                    to="https://linkedin.com/in/adityabatramvikas"
                    target="_blank"
                  >
                    <img src={linkedinIcon} alt="" className="icon" />
                  </Link>
                </div>
              </div>
              <div className="item">
                <img src={gunjanSingh} alt="" className="profile" />
                <div className="content">
                  <p>
                    <b>Gunjan Singh</b>
                    <span>CO-FOUNDER/ CTO</span>
                  </p>
                  <Link
                    to="https://linkedin.com/in/gunjansinghmvikas"
                    target="_blank"
                  >
                    <img src={linkedinIcon} alt="" className="icon" />
                  </Link>
                </div>
              </div>
              <div className="item">
                <img src={rajatKumar} alt="" className="profile" />
                <div className="content">
                  <p>
                    <b>Rajat Kumar</b>
                    <span>CFO</span>
                  </p>
                  <Link
                    to="https://linkedin.com/in/rajatkumarmvikas"
                    target="_blank"
                  >
                    <img src={linkedinIcon} alt="" className="icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Blog */}

        <div className="blog_section home_spacing" id="blog">
          <div className="container">
            <div className="page_title">
              <p>BLOG</p>
              <h3>Latest Blogs</h3>
            </div>
            <div className="card_wrap">
              <div className="item">
                <div className="blog_card">
                  <div className="image_wrap">
                    <img src={blog1} alt="" />
                  </div>
                  <div className="content">
                    <div className="user_detail">
                      <img src={teamImg1} alt="" />
                      Chirag Mudgil | Sunday , 1 Jan 2023
                    </div>
                    <h3>
                      How to Optimize Last-Mile Delivery Routes for Efficiency
                    </h3>
                    <p>
                      Last-mile delivery is one of the most crucial aspects of
                      logistics for any courier company. In this blog post, we
                      explore strategies and best practices for optimizing
                      last-mile delivery routes to improve efficiency and reduce
                      costs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="blog_card">
                  <div className="image_wrap">
                    <img src={blog2} alt="" />
                  </div>
                  <div className="content">
                    <div className="user_detail">
                      <img src={teamImg1} alt="" />
                      Akash Kumar | Saturday , 24 Feb 2023
                    </div>
                    <h3>
                      The Impact of Technology on Modern Logistics Operations
                    </h3>
                    <p>
                      Technology plays a significant role in shaping the modern
                      logistics industry. From route optimization algorithms to
                      real-time tracking systems, this blog post delves into how
                      technology is revolutionizing logistics operations and
                      enhancing customer experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="blog_card">
                  <div className="image_wrap">
                    <img src={blog3} alt="" />
                  </div>
                  <div className="content">
                    <div className="user_detail">
                      <img src={teamImg1} alt="" />
                      Sangeet Dhasmana | Friday , 10 May 2024
                    </div>
                    <h3>
                      Sustainable Logistics: Reducing Environmental Footprint in
                      Courier Services
                    </h3>
                    <p>
                      As environmental concerns continue to grow, courier
                      companies are under pressure to adopt sustainable
                      practices. This blog post discusses innovative approaches
                      to reducing the environmental footprint of logistics
                      operations and promoting eco-friendly courier services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* View breakup modal */}
      <CustomModal
        show={viewBreakupModal}
        handleClose={() => setViewBreakupModal(false)}
        className={"view_breakup_charges_modal"}
      >
        <h3>{vendorName}</h3>
        <ul>
          {vendorBreakUps &&
            Object.keys(vendorBreakUps).map((keyName, i) => (
              <li key={i}>
                <h5>{keyName}</h5>
                <h5>{vendorBreakUps[keyName]}</h5>
              </li>
            ))}
        </ul>
      </CustomModal>

      {/* Tooltip modal */}
      <CustomModal show={infoModal} handleClose={() => setInfoModal(false)}>
        {rulesList?.length > 0 ? (
          <>
            <h5>Rules</h5>
            <ul>
              {rulesList?.length > 0 &&
                rulesList?.map((el, i) => (
                  <li className="mb-2">
                    {i + 1}. {el?.value || "----"}
                  </li>
                ))}
            </ul>
          </>
        ) : (
          <>
            <p className="text-center">No Rules Available</p>
          </>
        )}
      </CustomModal>
    </>
  );
};

export default HomePage;
