import {
  downloadIcon,
  filterIcon,
  shortingIcon,
  tableShortingIcon,
} from "assets/icons";
import { Button, DatePicker, LoadingButton, Select } from "components/form";
import CustomModal from "components/modals/CustomModal";
import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { CgArrowsExchangeV } from "react-icons/cg";
import { Link, useParams } from "react-router-dom";
import { IoMdEyeOff } from "react-icons/io";
import {
  downloadDocketAPI,
  downloadMasterDocketAPI,
  downloadOrderLabelAPI,
  getOrderDetailAPI,
} from "services/user-controllers/order-controller";
import { toast } from "react-toastify";
import TableHead from "components/table/TableHead";
import TableBody from "components/table/TableBody";
import {
  filterOperatorDropdown,
  myOrderDetailsFilterColumns,
} from "./order-config/orderConfig";
import FilterModal from "components/filter/FilterModal";
import ColumnFilterDropdown from "components/filter/ColumnFilterDropdown";
import Pagination from "react-js-pagination";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { setDataToLocal } from "config/utils";
import { getDataToLocal } from "config/utils";

const ViewMyOrderDetails = () => {
  const { orderId } = useParams();
  const replacedOrderId = orderId.replace(/~/g, "/");

  const [loadingButton, setLoadingButton] = useState(false);
  const [downloadLabelState, setDownloadLabelState] = useState({
    id: "",
    status: false,
  });

  console.log("Download label state", downloadLabelState);

  function generateId() {
    let id = Math.floor(Math.random() * 10000);
    return id;
  }

  const downloadLabelHandler = async (e, itemId) => {
    const labelId = getDataToLocal("labelId");

    let cloneProcessed = myOrderDetailsColumns.map((col) => {
      return {
        ...col,
        isHidden: hiddenColumns.some((el) => el?.hiddenValue === col.key),
      };
    });
    setProcessedColumnsArray(cloneProcessed);
    try {
      const { data } = await downloadOrderLabelAPI(
        labelId,
        downloadLabelState?.id
      );
      if (data?.code === 200) {
        const orderLabelUrl = data.data.url;
        setTogglerSwitch(!togglerSwitch);
        window.open(orderLabelUrl, "_blank");
      } else {
        toast?.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDownloadLabelState({ id: "", status: false });
    }
  };

  const myOrderDetailsColumns = useMemo(() => {
    return [
      {
        sort: "DESC",
        enable: true,
        key: "Orderid",
        id: generateId(),
        name: "Order ID",
        icon: <FaArrowUp />,
      },
      {
        sort: "DESC",
        enable: true,
        key: "Itemid",
        name: "Item ID",
        id: generateId(),
        icon: <FaArrowUp />,
      },
      {
        sort: "DESC",
        enable: true,
        key: "Length",
        name: "Length",
        id: generateId(),
        icon: <FaArrowUp />,
      },
      {
        sort: "DESC",
        enable: true,
        name: "Width",
        key: "Breadth",
        id: generateId(),
        icon: <FaArrowUp />,
      },
      {
        sort: "DESC",
        enable: true,
        key: "Height",
        name: "Height",
        id: generateId(),
        icon: <FaArrowUp />,
      },
      {
        enable: true,
        key: "Itemid",
        actionId: "id",
        id: generateId(),
        name: "Download Label",
        formatHandler: function (item, col) {
          console.log("Item Id", item[col.actionId]);
          console.log("StateId", downloadLabelState?.id);
          if (
            item[col?.actionId] === downloadLabelState?.id &&
            downloadLabelState?.status
          ) {
            return (
              <td key={col.key} className="nowrap">
                Please wait...
              </td>
            );
          } else {
            return (
              <td key={col.key} className="nowrap">
                <button
                  type="button"
                  onClick={(e) => {
                    setDownloadLabelState({
                      status: true,
                      id: item[col.actionId],
                    });
                  }}
                >
                  <img src={downloadIcon} alt="" />
                </button>
              </td>
            );
          }
        },
      },
    ].filter((column) => column.enable);
  }, [downloadLabelState]);

  //************************* Handle all state **************************//
  const [activePage, setActivePage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [orderDetailsRecord, setOrderDetailsRecord] = useState([]);

  const [idForDocket, setIdForDocket] = useState("");
  const [docketButtonLoading, setDocketButtonLoading] = useState(false);
  const [masterDocketButtonLoading, setMasterDocketButtonLoading] =
    useState(false);

  const [processedColumnsArray, setProcessedColumnsArray] = useState(
    myOrderDetailsColumns
  );
  const [downloadLabelButtonLoading, setDownloadLabelButtonLoading] =
    useState(false);

  // const [downloadLabelState, setDownloadLabelState] = useState({
  //   id: "",
  //   status: downloadLabelButtonLoading,
  // });
  const [togglerSwitch, setTogglerSwitch] = useState(false);

  //---- state for handle sorting----//
  const [sortingToggler, setSortingToggler] = useState(false);
  const [sortingFilterField, setSortingFilterField] = useState({
    isSortApply: 0,
    sortColumnName: "",
    sortingOrder: "",
  });

  //------- handle all filter state ---------//
  const [limitRow, setLimitRow] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [startDateFilter, setStartDate] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [endDateFilter, setEndDateFilter] = useState("");
  const [filterModalFields, setFilterModalFields] = useState({
    columnFilterName: "",
    operatorFilterName: "",
    filterValue: "",
    isApplied: false,
  });

  //**************************** Handle all function ***************************//

  // pageChangeHandler for pagination
  const handlePageChange = (event) => {
    console.log(event);
    setActivePage(event);
  };

  useEffect(() => {
    if (downloadLabelState?.status) {
      downloadLabelHandler();
    }
  }, [downloadLabelState?.status]);

  //------------------ Functions for handle filter andy dynamic table columns --------------------//

  // toggleColumnVisibility handler for set array of hidden column name
  // const toggleColumnVisibility = (key) => {
  //   setHiddenColumns((prevState) =>
  //     prevState.includes(key)
  //       ? prevState.filter((col) => col !== key)
  //       : [...prevState, key]
  //   );
  // };

  const toggleColumnVisibility = (key) => {
    if (!hiddenColumns?.length) {
      let updateHidden = {
        sort: "DESC",
        icon: <FaArrowUp />,
        hiddenValue: key,
      };

      let hiddenArr = [updateHidden];
      setHiddenColumns(hiddenArr);
    } else {
      if (hiddenColumns.some((el) => el.hiddenValue === key)) {
        let updatedHiddenArray = hiddenColumns.filter(
          (el) => el.hiddenValue !== key
        );
        setHiddenColumns(updatedHiddenArray);
      } else {
        let clone = [...hiddenColumns];
        let updatedHiddenCol = {
          sort: "DESC",
          icon: <FaArrowUp />,
          hiddenValue: key,
        };
        clone = [...clone, { ...updatedHiddenCol }];
        setHiddenColumns(clone);
      }
    }
    setTogglerSwitch(!togglerSwitch);
  };

  // hideAllColumnsHandler for hide all columns of table
  // const hideAllColumns = () => {
  //   setHiddenColumns(myOrderDetailsColumns.map((col) => col.key));
  // };

  const hideAllColumns = () => {
    setHiddenColumns(
      myOrderDetailsColumns.map((col) => {
        return {
          sort: col.sort,
          icon: col.icon,
          hiddenValue: col?.key,
        };
      })
    );
    setTogglerSwitch(!togglerSwitch);
  };

  // Show all columns handler for show all table's data
  const showAllColumns = () => {
    setHiddenColumns([]);
    setTogglerSwitch(!togglerSwitch);
  };

  // sort toggle Handler
  const toggleSortHandler = (keyValue, col) => {
    let index = 0;
    let updatedCol;
    let isApplied = 0;
    let sortFields = {};
    let cloneProcessedArray = processedColumnsArray?.map((el) => el);

    if (cloneProcessedArray?.length > 0) {
      updatedCol = processedColumnsArray.find((el) => el.key === keyValue);
      index = cloneProcessedArray.findIndex((obj) => obj.key === keyValue);
      if (updatedCol?.sort === "DESC") {
        updatedCol = {
          ...updatedCol,
          sort: "ASC",
          icon: <FaArrowDown />,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === keyValue),
        };
        isApplied = 1;
      } else {
        updatedCol = {
          ...updatedCol,
          sort: "DESC",
          icon: <FaArrowUp />,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === keyValue),
        };
        isApplied = 2;
      }
    }
    cloneProcessedArray[index] = updatedCol;
    setProcessedColumnsArray(cloneProcessedArray);

    if (isApplied === 1 || isApplied === 2) {
      sortFields = {
        isSortApply: isApplied,
        sortColumnName: keyValue,
        sortingIcon: updatedCol?.icon,
        sortingOrder: updatedCol?.sort,
      };
    }
    setSortingFilterField({ ...sortFields });
    setSortingToggler(!sortingToggler);
  };

  // function for set column array with there column visibility checked by check and uncheck
  // const processedColumns = myOrderDetailsColumns.map((col) => ({
  //   ...col,
  //   isHidden: hiddenColumns.includes(col.key),
  // }));

  //------------------ Close Functions for handle filter andy dynamic table columns --------------------//

  // get order detailsHandler
  const getOrderDetailsHandler = async (
    page = activePage,
    limit = limitRow,
    isSort = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.isSortApply
      : 0,
    sortColumnName = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.sortColumnName
      : "",
    sortingOrder = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.sortingOrder
      : "",

    column = filterModalFields?.columnFilterName,
    operator = filterModalFields?.operatorFilterName,
    value = filterModalFields?.filterValue
  ) => {
    try {
      const { data } = await getOrderDetailAPI(
        replacedOrderId,
        page,
        limit,
        isSort,
        sortColumnName,
        sortingOrder,
        column,
        operator,
        value
      );
      console.log("ORDER DETAILS API RESPONSE ========>>>>>>> ", data);
      if (data.code === 200) {
        const orderIdForLabel = data?.data?.orderId;
        setDataToLocal("labelId", orderIdForLabel);
        setIdForDocket(data?.data?.orderId);

        // setDownloadLabelState({id:data?.data?.list})
        setOrderDetailsRecord(data?.data?.list);
        setTotalRecord(data?.data?.count || 0);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Function For Download Docket
  const downloadDocketHandler = async () => {
    try {
      setDocketButtonLoading(true);
      const { data } = await downloadDocketAPI(idForDocket);
      if (data?.code === 200) {
        const docketUrl = data.data.url;
        window.open(docketUrl, "_blank");
      } else {
        toast?.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDocketButtonLoading(false);
    }
  };

  // Function For Download Master Docket
  const downloadMasterDocketHandler = async () => {
    try {
      setMasterDocketButtonLoading(true);
      const { data } = await downloadMasterDocketAPI(idForDocket);
      if (data?.code === 200) {
        const masterDocketUrl = data.data.url;
        window.open(masterDocketUrl, "_blank");
      } else {
        toast?.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setMasterDocketButtonLoading(false);
    }
  };

  // useEffect for get order details
  useEffect(() => {
    getOrderDetailsHandler();

    // eslint-disable-next-line
  }, [
    activePage,
    limitRow,
    sortingFilterField.isSortApply,
    sortingToggler,
    filterModalFields?.isApplied,
  ]);

  // useEffect for handle processed column
  useEffect(() => {
    const processedColumnHandler = () => {
      let cloneProcessed = myOrderDetailsColumns.map((col) => {
        return {
          ...col,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === col.key),
        };
      });

      setProcessedColumnsArray(cloneProcessed);
    };

    processedColumnHandler();
  }, [togglerSwitch]);

  //******************************** Handle HTML CODE ***************************//
  return (
    <>
      <div className="my_orders_list">
        <div className="dashboard_title">
          <h4>Barcode</h4>
        </div>

        {/* <div className="button_section">
          <div className="form_field">
            <div className="search_box">
              <IoSearchOutline />
              <input
                type="search
                "
                // value={searchValue}
                placeholder="Search"
                className="from-control input"
                // onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          <div className="form_field">
            <Select
            //   value={statusValue}
            >
              <option value={""}>Select Status</option>
              <option value="serviceable">Serviceable</option>
              <option value="ODA1">ODA1</option>
              <option value="ODA2">ODA2</option>
              <option value="ODA3">ODA3</option>
            </Select>
          </div>
          <div className="form_field">
            <DatePicker placeholder="From" />
          </div>
          <div className="form_field">
            <DatePicker placeholder="To" />
          </div>

          <Button>Search</Button>
        </div> */}

        {/*----------- Handle Filter and Column filter -------------*/}
        <div className="table_filter_wrap cstm_table_wrap_filter">
          <div className="filter_buttons">
            {/* handle filters dropdown */}
            <Dropdown>
              <Dropdown.Toggle onClick={() => setFilterModal(true)}>
                <img src={filterIcon} alt="" />
                <span>Filters</span>
              </Dropdown.Toggle>
            </Dropdown>

            {/* handle column filter dropdown */}
            <ColumnFilterDropdown
              columnsArray={processedColumnsArray}
              onToggleColumn={toggleColumnVisibility}
              hideAllColumns={hideAllColumns}
              showAllColumns={showAllColumns}
            />

            {/* Sort By Filter HTML */}
            {/* <Dropdown>
              <Dropdown.Toggle>
                <img src={shortingIcon} alt="" />
                <span>Sort by</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={"span"}>Sort by ASC</Dropdown.Item>
                <Dropdown.Item as={"span"}>Sort by DESC</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>

          <div className="button_wrap">
            <LoadingButton
              loading={docketButtonLoading}
              disabled={docketButtonLoading}
              onClick={downloadDocketHandler}
              className={docketButtonLoading ? "disabled light" : "light"}
            >
              Download Docket
            </LoadingButton>

            <LoadingButton
              loading={masterDocketButtonLoading}
              disabled={masterDocketButtonLoading}
              onClick={downloadMasterDocketHandler}
              className={masterDocketButtonLoading ? "disabled light" : "light"}
            >
              Master Docket
            </LoadingButton>
          </div>
        </div>

        <div className="table_wrap">
          <table className="table">
            <TableHead
              sortHandler={toggleSortHandler}
              columnsArray={processedColumnsArray}
            />
            <TableBody
              labelId={idForDocket}
              listingData={orderDetailsRecord}
              columnsArray={processedColumnsArray}
            />
          </table>
        </div>
        {/*-------- Handle Pagination ---------*/}
        {totalRecord > 0 && (
          <div className="pagination_wrapper">
            <div className="pages_count">
              <p>
                <span>Rows per pages</span>{" "}
                <Select
                  value={limitRow}
                  onChange={(e) => setLimitRow(e.target.value)}
                >
                  <option value={5}>05</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </Select>
              </p>
              {/* <span className="page_tag">1-6 of 6</span> */}
            </div>

            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              pageRangeDisplayed={5}
              activePage={activePage}
              // prevPageText={"Prev"}
              // nextPageText={"Next"}
              // itemClassPrev={"prev"}
              // itemClassNext={"next"}
              onChange={handlePageChange}
              itemsCountPerPage={limitRow}
              totalItemsCount={totalRecord}
              itemClassLast={"last_page next"}
              itemClassFirst={"first_page prev"}
            />
          </div>
        )}
      </div>

      {/* filter dropdown modal */}
      <CustomModal
        show={filterModal}
        handleClose={() => setFilterModal(false)}
        className={"filterModal"}
      >
        <FilterModal
          filterFields={filterModalFields}
          setModalVisibility={setFilterModal}
          setFilterFields={setFilterModalFields}
          operatorArray={filterOperatorDropdown}
          columnArray={myOrderDetailsFilterColumns}
        />
      </CustomModal>
    </>
  );
};

export default ViewMyOrderDetails;
