import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Select } from "../../../../../../components/form";
import CustomModal from "../../../../../../components/modals/CustomModal";
import { addressListingApi } from "../../../../../../services/user-controllers/addressCont";
import { toast } from "react-toastify";
import AddressHandler from "../../../settings/section/address-management/AddressHandler";
import { getPickUpListingAPI } from "../../../../../../services/user-controllers/order-controller";
import { GlobalOrderContext } from "../../../../../../context/OrderContext";
import { getDataToLocal, setDataToLocal } from "../../../../../../config/utils";

const OrderPickupDetails = ({ prev, next }) => {
  const { vendorDetail, setOrderDetailsForm, orderDetailsForm } =
    useContext(GlobalOrderContext);

  //******************** Handle all state *******************//
  const [addressListing, setAddressListing] = useState([]);
  const [pickupSlotListing, setPickupSlotListing] = useState([]);

  const [pickUpDetailsFormFields, setPickUpDetailsFormFields] = useState({
    Pickupaddress: "",
    Pickuppersonname: "",
    PickupCompanyName: "",
    Pickuppersonmobile: "",
    pickuptime: "",
    PickupAddressId: "",
  });

  const [
    pickUpDetailsFormFieldsValidationError,
    setPickUpDetailsFormFieldsValidationError,
  ] = useState({
    Pickupaddress: "",
    Pickuppersonname: "",
    PickupCompanyName: "",
    Pickuppersonmobile: "",
    pickuptime: "",
    PickupAddressId: "",
  });

  //-----------Handle boolean states -----------//
  const [AddNewAddress, setAddNewAddress] = useState();

  //******************** Handle all Functions *******************//
  // get addressList handler
  const getAddressListHandler = async () => {
    try {
      const { data } = await addressListingApi();
      if (data?.code === 200) {
        setAddressListing(data.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get pickup slot listing handler
  const getPickUpSlotHandler = async (vendor = vendorDetail?.vendorId) => {
    try {
      const { data } = await getPickUpListingAPI(vendor);
      if (data?.code === 200) {
        setPickupSlotListing(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "Pickuppersonmobile") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setPickUpDetailsFormFields({
        ...pickUpDetailsFormFields,
        Pickuppersonmobile: numericValue,
      });
    } else {
      setPickUpDetailsFormFields({ ...pickUpDetailsFormFields, [name]: value });
    }
  };

  // ErrorHandler
  const errorHandler = () => {
    let errorField = {};
    let formIsValid = true;

    if (!pickUpDetailsFormFields.Pickuppersonname) {
      formIsValid = false;
      errorField["Pickuppersonname"] = "Please Enter Pickup Person Name*";
      setPickUpDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!pickUpDetailsFormFields.PickupCompanyName) {
      formIsValid = false;
      errorField["PickupCompanyName"] = "Please Enter Pickup Company Name*";
      setPickUpDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!pickUpDetailsFormFields.Pickuppersonmobile) {
      formIsValid = false;
      errorField["Pickuppersonmobile"] = "Please Enter Pickup Person Mobile*";
      setPickUpDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!pickUpDetailsFormFields.pickuptime) {
      formIsValid = false;
      errorField["pickuptime"] = "Please Select Pickup Slot*";
      setPickUpDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!pickUpDetailsFormFields.Pickupaddress) {
      formIsValid = false;
      errorField["Pickupaddress"] = "Please Select Pickup Address*";
      setPickUpDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    setPickUpDetailsFormFieldsValidationError(errorField);
    return formIsValid;
  };

  // onSubmitHandler
  const onSubmitHandler = () => {
    if (errorHandler()) {
      let addressObj = addressListing.find(
        (el) => el.fullAddress === pickUpDetailsFormFields?.Pickupaddress
      );
      let addressId = addressObj?.id;
      const orderData = {
        ...pickUpDetailsFormFields,
        PickupAddressId: addressId,
      };

      const dataForGlobalState = {
        ...orderDetailsForm,
        pickupDetailsForm: orderData,
      };
      setOrderDetailsForm(dataForGlobalState);
      next();
      // setDataToLocal("orderFormFields", dataForGlobalState);
    }
  };

  // useEffect for get address listing
  useEffect(() => {
    getAddressListHandler();

    // eslint-disable-next-line
  }, []);

  // useEffect for get pickup slot listing
  useEffect(() => {
    if (vendorDetail?.vendorId) {
      getPickUpSlotHandler();
    }

    // eslint-disable-next-line
  }, [vendorDetail?.vendorId]);

  // useEffect for set default address and pickup slot value from listing
  useEffect(() => {
    if (
      addressListing?.length > 0 &&
      pickupSlotListing?.length > 0 &&
      !orderDetailsForm?.pickupDetailsForm
    ) {
      setPickUpDetailsFormFields({
        ...pickUpDetailsFormFields,
        // Pickupaddress: addressListing[0]?.fullAddress,
        pickuptime: pickupSlotListing[0]?.value,
      });
    }

    // eslint-disable-next-line
  }, [addressListing, pickupSlotListing]);

  // useEffect for setPickUpDetails form value if got
  useEffect(() => {
    if (orderDetailsForm?.pickupDetailsForm) {
      setPickUpDetailsFormFields({ ...orderDetailsForm?.pickupDetailsForm });
    }
  }, [orderDetailsForm?.pickupDetailsForm]);

  // useEffect for update order form details global state from local
  // useEffect(() => {
  //   const orderData = getDataToLocal("orderFormFields");
  //   if (!orderDetailsForm?.pickupDetailsForm && orderData) {
  //     setOrderDetailsForm({
  //       ...orderData,
  //       pickupDetailsForm: { ...orderData?.pickupDetailsForm },
  //     });
  //   }
  // }, []);

  //******************** Handle HTML CODE *******************//
  return (
    <>
      <h4>Pickup Details</h4>
      <form>
        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              name="Pickuppersonname"
              onChange={onChangeHandler}
              placeholder="Enter Pickup Person Name"
              label="Pickup Person Name*"
              value={pickUpDetailsFormFields?.Pickuppersonname}
              error={pickUpDetailsFormFieldsValidationError?.Pickuppersonname}
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              name="PickupCompanyName"
              onChange={onChangeHandler}
              placeholder="Enter Pickup Company Name"
              label="Pickup Company Name*"
              value={pickUpDetailsFormFields?.PickupCompanyName}
              error={pickUpDetailsFormFieldsValidationError?.PickupCompanyName}
            />
          </div>
        </div>
        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              maxLength={11}
              name="Pickuppersonmobile"
              onChange={onChangeHandler}
              label="Pickup Person Contact*"
              placeholder="Enter Pickup Person Contact"
              value={pickUpDetailsFormFields?.Pickuppersonmobile}
              error={pickUpDetailsFormFieldsValidationError?.Pickuppersonmobile}
            />
          </div>
          <div className="form_field">
            <Select
              label="Pickup Slot*"
              name="pickuptime"
              onChange={onChangeHandler}
              value={pickUpDetailsFormFields?.pickuptime}
              error={pickUpDetailsFormFieldsValidationError?.pickuptime}
            >
              {pickupSlotListing?.length > 0 &&
                pickupSlotListing?.map((el) => (
                  <option key={el?.id} value={el?.value}>
                    {el?.value}
                  </option>
                ))}
            </Select>
          </div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <Select
              label="Pickup Address*"
              name="Pickupaddress"
              value={pickUpDetailsFormFields?.Pickupaddress}
              onChange={(e) =>
                setPickUpDetailsFormFields({
                  ...pickUpDetailsFormFields,
                  Pickupaddress: e.target.value,
                })
              }
              error={pickUpDetailsFormFieldsValidationError?.Pickupaddress}
            >
              <option value={""}>Select Address</option>

              {addressListing?.length > 0 &&
                addressListing?.map((el) => (
                  <option key={el.id} value={el?.fullAddress}>
                    {el?.fullAddress}
                  </option>
                ))}
            </Select>
          </div>
          <div className="form_field">
            <label className="label dummy">Add new address</label>
            <button
              type="button"
              className="input"
              onClick={() => setAddNewAddress(true)}
            >
              + Add new address
            </button>
          </div>
        </div>
        <div className="button_wrap button_right">
          <label className="label dummy"></label>
          <Button onClick={onSubmitHandler}>Continue</Button>
        </div>
      </form>

      <CustomModal
        show={AddNewAddress}
        handleClose={() => setAddNewAddress(false)}
      >
        <AddressHandler
          title={"Add New Address"}
          callRole={"add"}
          addressList={getAddressListHandler}
          closeAddressModal={() => setAddNewAddress(false)}
        />
      </CustomModal>
    </>
  );
};

export default OrderPickupDetails;
