import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Select } from "../../../../../../components/form";
import CustomModal from "../../../../../../components/modals/CustomModal";
import { GlobalOrderContext } from "../../../../../../context/OrderContext";
import { addressListingApi } from "../../../../../../services/user-controllers/addressCont";
import {
  getDeliveryListingAPI,
  getPickUpListingAPI,
} from "../../../../../../services/user-controllers/order-controller";
import { toast } from "react-toastify";
import { getDataToLocal, setDataToLocal } from "../../../../../../config/utils";
import AddressHandler from "../../../settings/section/address-management/AddressHandler";

const OrderDeliveryDetails = ({ prev, next }) => {
  const { vendorDetail, orderDetailsForm, setOrderDetailsForm } =
    useContext(GlobalOrderContext);

  console.log("I AM ORDER DETIALS FORM ===`-`-`-`-`-->>>>", orderDetailsForm);

  //*********************** Handle all state *******************//
  const [addressListing, setAddressListing] = useState([]);
  const [deliverySlotListing, setDeliverySlotListing] = useState([]);

  const [deliveryDetailsFormFields, setDeliveryDetailsFormFields] = useState({
    deliveryaddressid: "",
    deliveryaddress: "",
    deliverypersonname: "",
    deliverypersonmobile: "",
    deliverypersonemail: "",
    deliveryslot: "",
  });

  const [
    deliveryDetailsFormFieldsValidationError,
    setDeliveryDetailsFormFieldsValidationError,
  ] = useState({
    deliveryaddressid: "",
    deliveryaddress: "",
    deliverypersonname: "",
    deliverypersonmobile: "",
    deliverypersonemail: "",
    deliveryslot: "",
  });

  const [extraErrorFields, setExtraErrorFields] = useState({
    deliverypersonemail: false,
    deliveryaddress: false,
  });

  //-----------Handle boolean states -----------//
  const [AddNewAddress, setAddNewAddress] = useState();

  //*********************** Handle all function *******************//
  // get addressList handler
  const getAddressListHandler = async () => {
    try {
      const { data } = await addressListingApi();
      console.log("ADDRESS LISTING API RES ====>>>>", data);
      if (data?.code === 200) {
        setAddressListing(data.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get pickup slot listing handler
  const getDeliverySlotHandler = async (vendor = vendorDetail?.vendorId) => {
    try {
      const { data } = await getDeliveryListingAPI(vendor);
      if (data?.code === 200) {
        setDeliverySlotListing(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  // ErrorHandler
  const errorHandler = () => {
    let errorField = {};
    let extraError = { deliverypersonemail: false, deliveryaddress: false };
    let formIsValid = true;

    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let pickUpAddress = orderDetailsForm?.pickupDetailsForm?.Pickupaddress;

    if (!deliveryDetailsFormFields.deliverypersonname) {
      formIsValid = false;
      errorField["deliverypersonname"] = "Please Enter Delivery Person Name*";
      setDeliveryDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    // if (!deliveryDetailsFormFields.deliverypersonemail) {
    //   formIsValid = false;
    //   errorField["deliverypersonemail"] = "Please Enter Delivery Person Email*";
    //   setDeliveryDetailsFormFieldsValidationError(errorField);
    //   return formIsValid;
    // }
    if (
      deliveryDetailsFormFields.deliverypersonemail &&
      !deliveryDetailsFormFields?.deliverypersonemail?.match(mailFormat)
    ) {
      formIsValid = false;
      errorField["deliverypersonemail"] = "Invalid Email";
      extraError["deliverypersonemail"] = true;
      setExtraErrorFields(extraError);
      setDeliveryDetailsFormFieldsValidationError(errorField);
    }

    if (!deliveryDetailsFormFields.deliverypersonmobile) {
      formIsValid = false;
      errorField["deliverypersonmobile"] =
        "Please Enter Delivery Person Mobile*";
      setDeliveryDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!deliveryDetailsFormFields.deliveryslot) {
      formIsValid = false;
      errorField["deliveryslot"] = "Please Select Delivery Slot*";
      setDeliveryDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!deliveryDetailsFormFields.deliveryaddress) {
      formIsValid = false;
      errorField["deliveryaddress"] = "Please Select Delivery Address*";
      setDeliveryDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    } else if (
      deliveryDetailsFormFields.deliveryaddress &&
      deliveryDetailsFormFields.deliveryaddress === pickUpAddress
    ) {
      formIsValid = false;
      errorField["deliveryaddress"] =
        "Delivery and Pickup Address must be defferent not same*";
      setDeliveryDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    setExtraErrorFields({ ...extraError });
    setDeliveryDetailsFormFieldsValidationError(errorField);
    return formIsValid;
  };

  // onSubmitHandler
  const onSubmitHandler = () => {
    if (errorHandler()) {
      let addressObj = addressListing.find(
        (el) => el.fullAddress === deliveryDetailsFormFields?.deliveryaddress
      );
      let addressId = addressObj?.id;
      const orderData = {
        ...deliveryDetailsFormFields,
        deliveryaddressid: addressId,
      };

      const dataForGlobalState = {
        ...orderDetailsForm,
        deliveryDetailsForm: orderData,
      };
      setOrderDetailsForm(dataForGlobalState);
      next();
      // setDataToLocal("orderFormFields", dataForLocal);
    }
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "deliverypersonmobile") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setDeliveryDetailsFormFields({
        ...deliveryDetailsFormFields,
        deliverypersonmobile: numericValue,
      });
    } else {
      setDeliveryDetailsFormFields({
        ...deliveryDetailsFormFields,
        [name]: value,
      });
    }
  };

  // useEffect for get address listing
  useEffect(() => {
    getAddressListHandler();

    // eslint-disable-next-line
  }, []);

  // useEffect for get delivery slot listing
  useEffect(() => {
    if (vendorDetail?.vendorId) {
      getDeliverySlotHandler();
    }

    // eslint-disable-next-line
  }, [vendorDetail?.vendorId]);

  // useEffect for set default address and delivery slot value from listing
  useEffect(() => {
    if (
      addressListing?.length > 0 &&
      deliverySlotListing?.length > 0 &&
      !orderDetailsForm?.deliveryDetailsForm
    ) {
      setDeliveryDetailsFormFields({
        ...deliveryDetailsFormFields,
        // deliveryaddress: addressListing[0]?.fullAddress,
        deliveryslot: deliverySlotListing[0]?.value,
      });
    }

    // eslint-disable-next-line
  }, [addressListing, deliverySlotListing]);

  // useEffect for setDeliveryDetails form value if got
  useEffect(() => {
    if (orderDetailsForm?.deliveryDetailsForm) {
      setDeliveryDetailsFormFields({
        ...orderDetailsForm?.deliveryDetailsForm,
      });
    }
  }, [orderDetailsForm?.deliveryDetailsForm]);

  // useEffect for update order form details global state from local
  // useEffect(() => {
  //   const orderData = getDataToLocal("orderFormFields");
  //   if (
  //     !orderDetailsForm?.deliveryDetailsForm &&
  //     orderData?.deliveryDetailsForm
  //   ) {
  //     setOrderDetailsForm({
  //       ...orderData,
  //       deliveryDetailsForm: { ...orderData?.deliveryDetailsForm },
  //     });
  //   }
  // }, []);

  console.log(
    "DELIVERY DETAILS FORMS VALIDATION ERROR ===----->>",
    deliveryDetailsFormFieldsValidationError
  );

  //*********************** Handle HTML CODE *******************//
  return (
    <>
      <h4>Delivery Details</h4>
      <form>
        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              name="deliverypersonname"
              onChange={onChangeHandler}
              placeholder="Enter Delivery Person Name"
              label="Delivery Person Name*"
              value={deliveryDetailsFormFields?.deliverypersonname}
              error={
                deliveryDetailsFormFieldsValidationError?.deliverypersonname
              }
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              name="deliverypersonemail"
              onChange={onChangeHandler}
              label="Delivery Person Email"
              placeholder="Enter Delivery Person Email"
              extraError={extraErrorFields?.deliverypersonemail}
              value={deliveryDetailsFormFields?.deliverypersonemail}
              error={
                deliveryDetailsFormFieldsValidationError?.deliverypersonemail
              }
            />
          </div>
        </div>
        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              maxLength={11}
              name="deliverypersonmobile"
              onChange={onChangeHandler}
              placeholder="Enter Delivery Person Contact"
              label="Delivery Person Contact*"
              value={deliveryDetailsFormFields?.deliverypersonmobile}
              error={
                deliveryDetailsFormFieldsValidationError?.deliverypersonmobile
              }
            />
          </div>
          <div className="form_field">
            <Select
              name="deliveryslot"
              label="Delivery Slot*"
              onChange={onChangeHandler}
              value={deliveryDetailsFormFields?.deliveryslot}
              error={deliveryDetailsFormFieldsValidationError?.deliveryslot}
            >
              {deliverySlotListing?.length > 0 &&
                deliverySlotListing?.map((el) => (
                  <option key={el?.id} value={el?.value}>
                    {el?.value}
                  </option>
                ))}
            </Select>
          </div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <Select
              name="deliveryaddress"
              label="Delivery Address*"
              onChange={(e) =>
                setDeliveryDetailsFormFields({
                  ...deliveryDetailsFormFields,
                  deliveryaddress: e.target.value,
                })
              }
              value={deliveryDetailsFormFields?.deliveryaddress}
              className={
                deliveryDetailsFormFieldsValidationError?.deliveryaddress &&
                "is-invalid"
              }
              error={deliveryDetailsFormFieldsValidationError?.deliveryaddress}
            >
              <option value={""}>Select Address</option>

              {addressListing?.length > 0 &&
                addressListing?.map((el) => (
                  <option key={el.id} value={el?.fullAddress}>
                    {el?.fullAddress}
                  </option>
                ))}
            </Select>
          </div>

          <div className="form_field">
            <label className="label dummy">Add new address</label>
            <button
              type="button"
              className="input"
              onClick={() => setAddNewAddress(true)}
            >
              + Add new address
            </button>
          </div>
        </div>
        <div className="button_wrap">
          <Button className={"light black"} onClick={prev}>
            Back
          </Button>
          <Button onClick={onSubmitHandler}>Continue</Button>
        </div>
      </form>

      <CustomModal
        show={AddNewAddress}
        handleClose={() => setAddNewAddress(false)}
      >
        <AddressHandler
          callRole={"add"}
          title={"Add New Address"}
          addressList={getAddressListHandler}
          closeAddressModal={() => setAddNewAddress(false)}
        />
      </CustomModal>
    </>
  );
};

export default OrderDeliveryDetails;
