import React, { useContext, useState } from "react";
import { authImg } from "../../../assets/images";
import { callIcon, lockIcon, messageIcon } from "../../../assets/icons";
import usePasswordToggle from "../../../hooks/usePasswordToggle";
import { Button, Input, LoadingButton } from "../../../components/form";
import "../auth.scss";
import OTPComponent from "../OTP/MobileOTP";
import { toast } from "react-toastify";
import ForgetPassword from "../forgoPassword/ForgotPassword";
import { type } from "@testing-library/user-event/dist/type";
import { personLoginApi } from "../../../services/auth-controllers/authCont";
import { encryptDataHandler, setDataToLocal } from "../../../config/utils";
import { useNavigate } from "react-router-dom";
import { GlobalUserContext } from "context/GlobalContext";

const LoginModal = ({ switchSingUp, switchForget }) => {
  const navigate = useNavigate();

  const { setIsLoginModalOpen } = useContext(GlobalUserContext);

  //********************* Handel all state *******************//
  const [isOTP, setIsOTP] = useState(false);
  const [passwordType, showIcon] = usePasswordToggle();
  const [loginMethod, setLoginMethod] = useState("email");
  const [mobileForLogin, setMobileForLogin] = useState(null);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [isForgetPasswordOpen, setIsForgetPasswordOpen] = useState(false);

  const forgetPassModalVisibilityHandler = () => {
    setIsForgetPasswordOpen(false);
  };

  const [loginFields, setLoginFields] = useState({
    email: "",
    password: "",
    type: loginMethod,
  });

  const [loginFieldsValidationError, setLoginFieldValidationError] = useState({
    email: "",
    mobile: "",
    password: "",
  });

  const [formInfoError, setFormInfoError] = useState({
    email: false,
    password: false,
    mobile: false,
  });

  //************************ Handel All Functions ***********************//
  // Error Handler
  const errorHandler = () => {
    let errorField = {};
    let infoErrorFiled = {
      email: false,
      mobile: false,
      password: false,
    };
    let formIsValid = true;
    // let numberFormat = /^(\+\d{1,3}\s)?\d{12}$/;

    let numberFormat = /^\d{1,12}$/;
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (loginMethod === "email") {
      if (!loginFields.email) {
        formIsValid = false;
        errorField["email"] = "Required *";
      } else if (!loginFields.email.match(mailFormat)) {
        formIsValid = false;
        errorField["email"] = "Invalid Email";
        infoErrorFiled["email"] = true;
      }

      if (!loginFields.password) {
        formIsValid = false;
        errorField["password"] = "Required *";
      }
      setFormInfoError(infoErrorFiled);
      setLoginFieldValidationError(errorField);
    } else {
      if (!mobileForLogin) {
        formIsValid = false;
        errorField["mobile"] = "Required *";
      } else if (!mobileForLogin.match(numberFormat)) {
        formIsValid = false;
        errorField["mobile"] = "Invalid Mobile";
        infoErrorFiled["mobile"] = true;
      }
      setFormInfoError(infoErrorFiled);
      setLoginFieldValidationError(errorField);
    }
    return formIsValid;
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (loginMethod === "email") {
      setLoginFields({ ...loginFields, [name]: value });
    } else {
      setMobileForLogin(value);
    }
  };

  // Otp send handler
  const sendOTPHandler = async (e) => {
    e.preventDefault();
    if (errorHandler()) {
      try {
        setSubmitButtonLoading(true);
        let sendData = {};
        if (loginMethod === "email") {
          sendData = {
            username: loginFields?.email,
            password: loginFields?.password,
            type: "username",
          };
        } else {
          sendData = {
            username: mobileForLogin,
            type: "mobile",
          };
        }
        const { data } = await personLoginApi(sendData);
        console.log("API RESPONSE LOGIN", data);

        if (data.code === 200) {
          if (loginMethod === "email") {
            const dataForLocal = {
              token: data?.data?.token,
              personType: {
                isUser: data?.data?.user?.isUser,
                isVendor: data?.data?.user?.isVender,
              },
              userDetails: {
                userId: data?.data?.user?.id,
                name: data?.data?.user?.name,
                email: data?.data?.user?.email,
                lastLogin: data?.data?.user?.lastLoginAt,
              },
            };
            if (!data?.data?.user?.isVendor) {
              setDataToLocal("authDetail", dataForLocal);
            } else {
              setDataToLocal("vendorAuthDetail", dataForLocal);
            }

            if (!data?.data?.user?.isVendor) {
              setSubmitButtonLoading(false);
              setIsLoginModalOpen(false);
              navigate(`/user/dashboard/home`);
              toast.success(data?.message);
            } else {
              setSubmitButtonLoading(false);
              setIsLoginModalOpen(false);
              //------------ Code For Build ------------//
              window.open(
                `${process.env.REACT_APP_PROVIDER_URL}home`,
                "_blank"
              );
              toast.success(data?.message);
              window.location.reload();
            }
          } else {
            setSubmitButtonLoading(false);
            setIsOTP(true);
            toast.success(data.message, { id: "signUp" });
          }
        } else {
          setSubmitButtonLoading(false);
          toast.error(data.message, { id: "signUp" });
        }
      } catch (err) {
        console.error(err);
        setSubmitButtonLoading(false);
        setIsLoginModalOpen(false);
      }
    }
  };

  //**************************** Handle HTML Code*************************//
  return (
    <>
      {!isForgetPasswordOpen ? (
        <div className="auth login">
          <div className="image_wrap">
            <img src={authImg} alt="" />
          </div>

          <>
            {!isOTP ? (
              <>
                <div className="form_wrap">
                  {loginMethod === "email" && <h3>Log in to your Account</h3>}
                  {loginMethod === "mobile" && <h3>Log in with OTP</h3>}

                  <p>Welcome to brand meet to explore the new thing </p>

                  <form>
                    <div className="login_tab">
                      <button
                        type="button"
                        className={loginMethod === "email" ? "active" : ""}
                        onClick={() => {
                          setMobileForLogin(null);
                          setLoginMethod("email");
                        }}
                      >
                        <img src={messageIcon} alt="" />
                        Email address
                      </button>
                      <button
                        type="button"
                        className={loginMethod === "mobile" ? "active" : ""}
                        onClick={() => {
                          setLoginFields({});
                          setLoginFieldValidationError({
                            email: "",
                            mobile: "",
                            password: "",
                          });
                          setLoginMethod("mobile");
                        }}
                      >
                        <img src={callIcon} alt="" />
                        Mobile with OTP
                      </button>
                    </div>

                    {loginMethod === "email" && (
                      <>
                        <div className="form_field">
                          <Input
                            type="email"
                            name="email"
                            value={loginFields?.email}
                            onChange={onChangeHandler}
                            placeholder="Email Address"
                            className="form-control input"
                            extraError={formInfoError?.email}
                            error={loginFieldsValidationError?.email}
                          />
                          <img src={messageIcon} alt="" className="icon" />
                        </div>

                        <div className="form_field">
                          <Input
                            name="password"
                            type={passwordType}
                            placeholder="Password"
                            onChange={onChangeHandler}
                            value={loginFields?.password}
                            extraError={formInfoError?.password}
                            className="form-control input password"
                            error={loginFieldsValidationError?.password}
                          />
                          <img src={lockIcon} alt="" className="icon" />
                          <span className="password_icons">{showIcon}</span>
                        </div>

                        <p className="move_forgot_password">
                          <button
                            type="button"
                            onClick={() => {
                              switchForget();
                              setIsForgetPasswordOpen(true);
                            }}
                          >
                            Forgot password ?
                          </button>
                        </p>

                        <div className="button_wrap">
                          {!submitButtonLoading ? (
                            <Button
                              className={"w-100"}
                              onClick={sendOTPHandler}
                            >
                              Login
                            </Button>
                          ) : (
                            <LoadingButton
                              disabled
                              className={"w-100 disabled"}
                              loading={submitButtonLoading}
                            />
                          )}
                        </div>
                        <p className="move_singUp">
                          Don't have an account?
                          <button type="button" onClick={switchSingUp}>
                            SignUp
                          </button>
                        </p>
                      </>
                    )}

                    {loginMethod === "mobile" && (
                      <>
                        <div className="form_field">
                          <Input
                            type="tel"
                            name="mobile"
                            onChange={onChangeHandler}
                            placeholder="Mobile Number"
                            value={mobileForLogin || ""}
                            className="form-control input"
                            extraError={formInfoError?.mobile}
                            error={loginFieldsValidationError?.mobile}
                          />

                          <img src={callIcon} alt="" className="icon" />
                        </div>
                        <div className="button_wrap">
                          {!submitButtonLoading ? (
                            <Button
                              className={"w-100"}
                              onClick={sendOTPHandler}
                            >
                              Login with OTP
                            </Button>
                          ) : (
                            <LoadingButton
                              disabled
                              className={"w-100 disabled"}
                              loading={submitButtonLoading}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </>
            ) : (
              <>
                <OTPComponent
                  callRole={"login"}
                  numberChange={() => setIsOTP(false)}
                  dataToSend={{
                    ...loginFields,
                    type: loginMethod,
                    mobile: mobileForLogin,
                  }}
                />
              </>
            )}
          </>
        </div>
      ) : (
        <>
          <ForgetPassword />
        </>
      )}

      {/* ---------------- Handle Forget password component -------------*/}
    </>
  );
};

export default LoginModal;
